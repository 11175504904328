import React, { Component } from 'react';
import ChessBoardUI from './chessboard';
import { Chess } from 'chess.js';
import TipBoard from './tipboard';
import GameHistoryBoard from './gamehistory';
import cloneDeep from 'lodash/cloneDeep';
import GameBeginConfirmation from './beginGameModal';
import TopNavbar from '../navbar';

// Get window height and width
function getWindowHeight() {
  const { innerHeight: height } = window;
  return height;
}

// TODO: resign confirmation, player_color data, button click data
const initialGameState = {
  game: new Chess(),
  displayed_board: new Chess(),
  game_result: '',
  player_time_left: 600, // 10min = 600
  player_time_left_when_move_begins: 600, // 10min = 600
  player_bonus_time: 10,
  tip_flag: false,
  gameplay_data: [],
  has_computed_tip: true,
  player_can_move: true,
  tip_bestmove: '',
  show_feedback: false,
  last_player_move: '',
  tip_bestmove_san: '',
  new_game: true,
  game_data_written: false,
  attempt_resign: false,
  last_ai_move: '',
  game_begin: false,
  tips_found_count: 0,
  tips_received_count: 0,
  selected_tips: '',
  moves_details: [],
  move: {},
  time_spent: 0,
  cp_diff: '',
  first_pos_cp: '',
  second_pos_cp: '',
};

const group_to_player_param = {
  1: { player_color: 'w', assigned_group: '1' },
  2: { player_color: 'w', assigned_group: '1' },
  3: { player_color: 'w', assigned_group: '2' },
  4: { player_color: 'w', assigned_group: '2' },
  5: { player_color: 'w', assigned_group: '3' },
  6: { player_color: 'w', assigned_group: '3' },
};

class Portal extends Component {
  constructor(props) {
    super(props);
    // Initialize Portal State
    this.state = initialGameState;
    this.state['game_id'] = new Date().getTime();
    this.state['player_color'] = group_to_player_param[5]['player_color'];
    this.state['assigned_group'] = group_to_player_param[5]['assigned_group'];
    this.state['window_height'] = getWindowHeight() * 0.65; // Use height as width bc the board is a square
    this.state['last_active_date'] = new Date().getTime();
    this.state['player_time_left'] = 600;
    this.state['player_time_left_when_move_begins'] = 600;
    this.state['player_bonus_time'] = 10;
    this.state.tips_found_count = 0;
    this.state.tips_received_count = 0;
    this.state.prev_bestmove = '';
    this.state.selected_tips = '';
    this.state.moves_details = [];
    this.state.move = {};
    this.state.time_spent = 0;
  }

  nextGame = () => {
    const nextGameID = new Date().getTime();
    let nextGameState = cloneDeep(initialGameState);
    nextGameState['game_id'] = nextGameID;
    nextGameState['player_color'] = group_to_player_param[5]['player_color'];
    nextGameState['assigned_group'] = group_to_player_param[5]['assigned_group'];
    nextGameState['game_begin'] = true;
    nextGameState['window_height'] = getWindowHeight() * 0.65; // Use height as width bc the board is a square
    nextGameState['last_active_date'] = new Date().getTime();
    nextGameState['player_time_left'] = 600;
    nextGameState['player_time_left_when_move_begins'] = 600;
    nextGameState['player_bonus_time'] = 10;
    this.setState(nextGameState);
  };

  // Set window dimension
  handleResize = () => {
    this.setState({ window_height: getWindowHeight() * 0.65 });
    //console.log(this.state.window_height);
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  // Monitor game_result and send data when game ends
  componentDidUpdate() {
    // Send Data at the end of the game
    if (this.state.game_result !== '' && this.state.game_data_written === false) {
      this.setState({ game_data_written: true });
    }
  }

  // setParentState: for children to set Portal State
  setParentState = kwargs => {
    this.setState(kwargs);
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <TopNavbar />
        </div>
        <div className="d-flex mt-2 justify-content-center">
          <div>
            <div className="row">
              <div className="col">
                <TipBoard {...this.state} {...this.props} setParentState={this.setParentState} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ChessBoardUI
                  {...this.state}
                  {...this.props}
                  setParentState={this.setParentState}
                  initialState={this.initialState}
                  nextGame={this.nextGame}
                />
              </div>
              <div className="col">
                <GameHistoryBoard {...this.state} {...this.props} />
              </div>
            </div>
          </div>
          <GameBeginConfirmation
            {...this.state}
            {...this.props}
            setParentState={this.setParentState}
          />
        </div>
      </React.Fragment>
    );
  }
}

export { Portal, initialGameState };
