import React, { Component } from 'react';

class ChessClock extends Component {
	constructor(props) {
		super(props);
		this.clockOn = false;
		this.timer = null;
	}

	componentDidUpdate(prevProps) {
        if (this.props.player_can_move === true && // Player' turn
			this.clockOn === false &&
			this.props.game_begin === true) {  // The update is not because the countdown
            
            this.timer = setInterval(() => {

                // If No time left
				if (this.props.player_time_left <= 0) {
					// print all states
					//console.log(this.props)
					//clearInterval(this.timer);
					//throw Error("Time out");
                    this.props.setParentState({
                        game_result: this.props.player_color === "w" ? -1 : 1
                    });
                    clearInterval(this.timer);
                }

                // If there is still time
				this.props.setParentState({
					player_time_left: Math.max(
						0,
						this.props.player_time_left - 1
					),
                });
                
			}, 1000);
			this.clockOn = true;
		}

		if (this.props.player_can_move === false) {
			this.clockOn = false;
			clearInterval(this.timer);
		}
	}

	convert_s_to_min = () => {
		let minute = Math.floor(this.props.player_time_left / 60).toString();
		let second = (this.props.player_time_left - minute * 60).toString();

		if (minute.length < 2) {
			minute = '0' + minute;
		}

		if (second.length < 2) {
			second = '0' + second;
		}

		return (
			<p className="mt-2 fs-3 align-middle text-center float-end" style={{ width: "100px", backgroundColor: 'rgb(0,0,0,0.1)'}}>
					{minute}:{second}
				</p>
		);
	};

	render() {
		return this.convert_s_to_min();
	}
}
export default ChessClock;
