import * as yup from 'yup';

export const useValidation = () => {
  const passwordMinLength = 8;
  const passwordIsRequired = 'Password is required';
  const passwordsMustMatch = 'Passwords must match';

  const emailValidation = yup.string().email('Invalid email').required('Email is required');

  const eloValidation = yup
    .number()
    .typeError('ELO must be a number')
    .min(1000, 'ELO must be at least 1000')
    .max(2850, 'ELO cannot exceed 2850');

  const fideLinkValidation = yup
    .string()
    .matches(/^https:\/\/ratings\.fide\.com\/profile\/\d+$/, {
      message: 'Please provide a valid FIDE profile URL',
    })
    .required('Link to FIDE is required');

  const passwordValidation = yup
    .string()
    .required(passwordIsRequired)
    .min(passwordMinLength, `Password must be at least ${passwordMinLength} characters long`);

  const repeatPasswordValidation = yup
    .string()
    .oneOf([yup.ref('password'), null], passwordsMustMatch)
    .required('Repeat password is required');

  return {
    eloValidation,
    fideLinkValidation,
    passwordValidation,
    repeatPasswordValidation,
    emailValidation,
  };
};
