import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import React from 'react';
import { useAuthState } from '../service/store/AuthContext';

// Collapsable Navbar
function TopNavbar(props) {
  const { token } = useAuthState();

  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="light" sticky="top">
      <Container>
        <Navbar.Brand href="/">
          <img src="./img/logo.png" alt="logo" style={{ width: '20%' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/tutorial" className="fs-5">
              Tutorial
            </Nav.Link>
            <Nav.Link href="/play" className="fs-5">
              Play
            </Nav.Link>
            {token && (
              <Nav.Link href="/profile" className="fs-5">
                Profile
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNavbar;
