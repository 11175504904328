import { Chessboard } from 'react-chessboard';
import React, { useEffect } from 'react';
import ChessClock from './tutorialChessClock';
import cloneDeep from 'lodash/cloneDeep';


const colorList = ['w', 'w', 'b']
const correctMove = ['g3f3', 'a5a6', 'f2f1']


function TutorialBoard(props) {

	useEffect(() => {
		if (props.player_move !== "" && props.game_result === "") {
			if (props.player_move === correctMove[props.game_id - 1]) {
				props.setParentState({ game_result: true })
			}
			else {
				props.setParentState({ game_result: false })
			}
		}

	}, [props])


	const player_color = colorList[props.game_id - 1]

	const makeAMove = (from, to, promotion, move_party_color) => {

		const gameCopy = cloneDeep(props.chessGame);
		const result = gameCopy.move({
			from: from,
			to: to,
			promotion: promotion,
		});
		props.setParentState({chessGame: gameCopy})

		return result; // null if the move was illegal, the move object if the move was legal
	};

	const onDrop = (sourceSquare, targetSquare) => {
		const move = makeAMove(
			sourceSquare, // from
			targetSquare, // to
			// TODO allow user to change promotion
			'q', // promotion
			player_color // move party color
		);

		// illegal move
		if (move === null) {
			return false;
		}

		if (move !== null) {
			const currMove = move.from + move.to
			props.setParentState({player_move: currMove })
			return true;
		}
	}

	// Define User Move
	const isDraggablePiece = (piece, sourceSquare) => {
		const piece_color = piece.piece[0];
		if (
			(props.game_result !== '') |
			(piece_color !== player_color)
		) {
			return false;
		} else {
			return true;
		}
	};

	return (
		<React.Fragment>
			<div>
				<div className="row">
					<Chessboard
						id="Board"
						position={props.chessGame.fen()}
						onPieceDrop={onDrop}
						isDraggablePiece={isDraggablePiece}
						boardOrientation={
							player_color === 'w'
								? 'white'
								: 'black'
						}
						boardWidth={props.window_height}
						snapToCursor={true}
						areArrowsAllowed={false}
					/>
				</div>
				<div>
					<ChessClock
						{...props}
						setParentState={props.setParentState}
					/>
				</div>
			</div>

		</React.Fragment>
	);


}

export default TutorialBoard;
