import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { useMutation } from '@tanstack/react-query';

import { useValidation } from '../hooks';
import resetPassword from '../service/auth/api/resetPassword';
import { useAuthDispatch, useAuthState } from '../service/store/AuthContext';

export const ResetPasswordPage = () => {
  const { repeatPasswordValidation, passwordValidation } = useValidation();
  const location = useLocation();
  const navigate = useNavigate();
  const { token: userToken } = useAuthState();
  const authDispatch = useAuthDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isOpen, setSsOpen] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const queryParams = new URLSearchParams(location.search);
  const uid = queryParams.get('uid');
  const token = queryParams.get('token');

  const { mutateAsync, isLoading } = useMutation(resetPassword);

  const AuthSchema = Yup.object().shape({
    confirmPassword: repeatPasswordValidation,
    password: passwordValidation,
  });

  const onClose = () => {
    setSsOpen(false);
  };

  const handleSignIn = async values => {
    setErrorMessage('');

    try {
      const data = await mutateAsync({
        new_password: values.password,
        token,
        uid,
      });
      if (data.status === 204 || data.status === 201) {
        navigate('/play?reset=ok', { replace: true });
        return;
      }

      if (data?.error) {
        setErrorMessage(data?.error);
      }
    } catch (error) {
      setErrorMessage('Ooops.. Something is wrong! Try it again.');
    }
  };

  const goHome = () => navigate('/');

  const handleLogout = useCallback(() => {
    localStorage.removeItem('authToken');
    authDispatch({ type: 'LOG_OUT' });
  }, [authDispatch]);

  useEffect(() => {
    if (userToken) {
      handleLogout();
    }
  }, [handleLogout, userToken]);

  return (
    <Modal
      show={isOpen}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-auth-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Header closeButton>Reset Password</Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          validationSchema={AuthSchema}
          onSubmit={handleSignIn}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <div className="input-group">
                  <Field
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    placeholder="Password"
                    className="form-control"
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </button>
                </div>
                <div className="position-absolute start-16">
                  <ErrorMessage name="password" component="div" className="text-danger" />
                </div>
              </Form.Group>

              <Form.Group controlId="confirmPassword">
                <Form.Label className="mt-4">Confirm Password</Form.Label>
                <div className="input-group">
                  <Field
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    className="form-control"
                  />
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <i className="bi bi-eye-slash"></i>
                    ) : (
                      <i className="bi bi-eye"></i>
                    )}
                  </button>
                </div>
                <div className="position-absolute start-16">
                  <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                </div>
              </Form.Group>

              {errorMessage && <p className="text-danger">{errorMessage}</p>}

              <div className="d-flex justify-content-center gap-3 mt-5">
                <Button
                  variant="primary"
                  type="submit"
                  className=" position-relative px-5 d-flex justify-content-center align-items-center"
                >
                  Accept
                  {isLoading && (
                    <span
                      className="spinner-border spinner-border-sm  position-absolute mx-3 end-0 m-6 top-10"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
                <Button
                  variant="danger"
                  type="button"
                  onClick={goHome}
                  className=" position-relative px-5 d-flex justify-content-center align-items-center"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};
