import axios from 'axios';

export const getMyProfile = async authToken => {
  if (authToken) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}api/v1/auth/users/`, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        return error.response;
      }
      throw new Error(error.response.data.message);
    }
  }
  throw new Error('Token not found');
};
