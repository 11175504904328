import React, { Component } from 'react';
import TipBoard from './tutorialTipBoard';
import cloneDeep from 'lodash/cloneDeep';
import TutorialBoard from './tutorialBoard';
import { TutorialResult } from './tutorialResultModal';
import { Chess } from 'chess.js';
import BeginTutorial from './beginTutorialModal';

// Get window height and width
function getWindowHeight() {
	const { innerHeight: height } = window;
	return height;
}

const fenList = [
	'7k/1p4pp/p5b1/2P5/3P2P1/P1rB2RP/K7/8 w - - 0 1',
	'6k1/ppp3pp/2n1p3/P3P3/2R5/1r3P1P/3R4/4K3 w - - 2 27',
	'8/8/2Rpkpp1/8/4P1P1/4K3/5r2/8 b - - 0 1',
];

// TODO: resign confirmation, player_color data, button click data
const initialGameState = {
	player_time_left: 60, // 1min = 60
	player_time_left_when_move_begins: 60, // 1min = 60
	game_result: '',
	player_move: '',
	ready: false
};

class TutorialPortal extends Component {
	constructor(props) {
		super(props);
		// Initialize Portal State
		this.state = initialGameState;
		this.state['game_id'] = 1;
		this.state['window_height'] = getWindowHeight() * 0.65; // Use height as width bc the board is a square
		this.state['chessGame'] = new Chess(fenList[0]);
	}

	nextGame = () => {
		const nextGameID = this.state.game_id + 1;
		let nextGameState = cloneDeep(initialGameState);
		nextGameState['game_id'] = nextGameID;
		nextGameState['ready'] = true;
		nextGameState['window_height'] = getWindowHeight() * 0.65; // Use height as width bc the board is a square
		nextGameState['chessGame'] = new Chess(fenList[nextGameID - 1]);
		this.setState(nextGameState);
	};

	// Set window dimension
	handleResize = () => {
		this.setState({ window_height: getWindowHeight() * 0.65 });
		//console.log(this.state.window_height);
	};

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
	}

	// setParentState: for children to set Portal State
	setParentState = (kwargs) => {
		this.setState(kwargs);
	};

	render() {
		return (
			<React.Fragment>
				<div>
					<div className="row mt-3">
						<div className="col-4"></div>
						<div className="col">
							<TipBoard {...this.state} {...this.props} />
						</div>
						<div className="col"></div>
					</div>
					<div className="row">
						<div className="col-4"></div>
						<div className="col">
							<TutorialBoard
								{...this.state}
								{...this.props}
								setParentState={this.setParentState}
								initialState={this.initialState}
								nextGame={this.nextGame}
							/>
						</div>
					</div>
				</div>
				<TutorialResult
					{...this.state}
					{...this.props}
					setParentState={this.setParentState}
					nextGame={this.nextGame}
				/>
				<BeginTutorial
					{...this.state}
					{...this.props}
					setParentState={this.setParentState}
				/>
			</React.Fragment>
		);
	}
}

export { TutorialPortal, initialGameState };
