import { Chessboard } from 'react-chessboard';
import React, { Component } from 'react';
import ChessClock from './chessclock';
import cloneDeep from 'lodash/cloneDeep';
import { ResignConfirmation } from './gameovermodal';
import { setTimeout } from 'timers-promises';

// Setup TipGen
var TipGen = new Worker('/js/stockfish.js');
TipGen.postMessage('setoption name Use NNUE value false');
TipGen.postMessage('setoption name UCI_LimitStrength value false');
TipGen.postMessage('setoption name MultiPV value 2'); // MultiPV = 2

function analyzeBoard(engine, game_history, compute_time) {
  engine.postMessage('position startpos moves' + game_history);
  engine.postMessage('go movetime ' + compute_time);
}

const first_second_diff = 60;
//const first_second_diff = 0;
const trivial_cutoff = 500; // not used
const AI_compute_time = 2500;
const TipGen_compute_time = 2500;
//const AI_compute_time = 200;
//const TipGen_compute_time = 10;
const AI_delay = false;
const AI_delay_time = 1000;

const openning_move_max_elo_map = {
  13: 10,
  14: 10,
  15: 11,
  16: 11,
  17: 12,
  18: 12,
  19: 13,
  20: 14,
  21: 14,
  22: 15,
  23: 15,
  24: 16,
  25: 16,
};

function tip_criteria(score_list, first_second_diff, trivial_cutoff) {
  // If fewer than 2 moves, return false
  for (let i = 0; i < 2; i++) {
    if (score_list[i] === null) {
      return false;
    }
  }

  // First diff
  if (score_list[0] - score_list[1] < first_second_diff) {
    return false;
  }

  /* 	// Trivial Check
	if (
		Math.abs(score_list[1]) > trivial_cutoff &&
		score_list[0] * score_list[1] > 0
	) {
		return false;
	} */

  // Otherwise, return true
  return true;
}

// ChessBoard with AI and TipGen
class ChessBoardUI extends Component {
  constructor(props) {
    super(props);

    this.score_list = [null, null];

    // Setup AI with targeted ELO
    this.AI = new Worker('/js/stockfish.js');
    this.AI.postMessage('setoption name Use NNUE value false');
    this.AI.postMessage('setoption name UCI_LimitStrength value true');
    // Add listener
    this.AI.onmessage = this.makeAIBestMove;
    TipGen.onmessage = this.checkTip;
  }

  // Initial turn
  componentDidMount() {
    //this.callTipGen();
    // If AI moves first, set has_computed_tip to be true
    if (this.props.player_color === 'b') {
      this.props.setParentState({ has_computed_tip: true });
    }
    this.callAI();
  }

  // Determine Game Turn
  componentDidUpdate() {
    // Set AI ELO
    /*     if (this.props.AI_elo !== -1 && this.props.game.history().length <= 2) {
      this.AI.postMessage('setoption name UCI_LimitStrength value true');
      this.AI.postMessage('setoption name UCI_Elo value ' + 2000);
    } else if (
      this.props.AI_elo !== -1 &&
      this.props.game.history().length <=
        parseInt(openning_move_max_elo_map[this.props.user.elo?.toString().slice(0, 2)]) * 2
    ) {
      console.log(openning_move_max_elo_map[this.props.user.elo?.toString().slice(0, 2)] * 2);
      this.AI.postMessage('setoption name UCI_LimitStrength value false');
    } else if (this.props.AI_elo !== -1) {
      console.log(this.props.AI_elo)
      this.AI.postMessage('setoption name UCI_LimitStrength value true');
      this.AI.postMessage('setoption name UCI_Elo value ' + this.props.AI_elo);
    }
 */
    if (this.props.AI_elo !== -1 && this.props.game.history().length <= 2) {
      console.log('First step, max ELO');
      this.AI.postMessage('setoption name UCI_LimitStrength value false');
    } else if (
      this.props.AI_elo !== -1 &&
      this.props.game.history().length <=
        parseInt(openning_move_max_elo_map[this.props.user.elo?.toString().slice(0, 2)]) * 2
    ) {
      console.log(
        'Max ELO for ' +
          parseInt(openning_move_max_elo_map[this.props.user.elo?.toString().slice(0, 2)]) * 2 +
          ' steps',
      );
      this.AI.postMessage('setoption name UCI_LimitStrength value false');
      console.log('The player ELO is: ' + this.props.user.elo);
    } else if (
      this.props.AI_elo !== -1 &&
      this.props.game.history().length > 80 && // moves above 80
      parseInt(this.props.user.elo) > 2000 // player ELO above 2000
    ) {
      console.log('Move > 40, max ELO');
      this.AI.postMessage('setoption name UCI_LimitStrength value false');
    } else if (this.props.AI_elo !== -1) {
      console.log('Normal ELO');
      this.AI.postMessage('setoption name UCI_LimitStrength value true');
      this.AI.postMessage('setoption name UCI_Elo value ' + this.props.AI_elo);
    }

    if (this.props.game_result === '') {
      // Initial turn of games 2, 3, 4, ...
      // The Player has read the agreement
      /* 		if (this.props.new_game === true && this.props.AI_elo !== -1) {
  // Determine Game Turn
  componentDidUpdate() {
    // Set AI ELO
    if (this.props.AI_elo !== -1 && this.props.game.history().length <= 2) {
      this.AI.postMessage('setoption name UCI_LimitStrength value true');
      this.AI.postMessage('setoption name UCI_Elo value ' + 2000);
    } else if (this.props.AI_elo !== -1 && this.props.game.history().length <= 20) {
      this.AI.postMessage('setoption name UCI_LimitStrength value false');
    } else if (this.props.AI_elo !== -1 && this.props.game.history().length > 20) {
      this.AI.postMessage('setoption name UCI_LimitStrength value true');
      this.AI.postMessage('setoption name UCI_Elo value ' + this.props.AI_elo);
    }

    if (this.props.game_result === '') {
      // Initial turn of games 2, 3, 4, ...
      // The Player has read the agreement
      /* 		if (this.props.new_game === true && this.props.AI_elo !== -1) {
			//console.log('Restart Game');
			//this.callTipGen();
			// If AI moves first, set has_computed_tip to be true
			if (this.props.player_color === 'b') {
				this.props.setParentState({ has_computed_tip: true });
			}
			this.callAI();
			this.props.setParentState({ new_game: false });
		} */
      // Check Idle Time: When
      var idleTime = Math.floor((new Date().getTime() - this.props.last_active_date) / 1000);
      //console.log(idleTime)
      if (idleTime > 900) {
        console.log('Idle for too long');
        window.open('about:blank', '_self');
      }

      this.callTipGen();
      this.callAI();
      this.checkGameResult();
      //this.props.setParentState({
      //		new_game: false,
      //	displayed_board: this.props.game,
      //});
    } else {
      console.log('Game Over');
      //console.log(this.props.game_result)
    }
  }

  callTipGen = () => {
    if (
      this.props.game.isGameOver() === false && // Check if the game is over
      this.props.game_result === '' && // Check if player has resigned or out of time
      this.props.game.turn() === this.props.player_color && // player's turn
      this.props.has_computed_tip === false && // TipGen has computed this tip
      this.props.player_can_move === false && // player can move after TipGen finishes computation
      this.props.player_id !== -1 //  Make sure the player has logged in
    ) {
      // Call TipGen to show tips
      console.log('TipGen is called');
      analyzeBoard(
        TipGen, // Engine
        this.getHistory(), // Game History
        TipGen_compute_time, // Compute Time in ms
      );
      // Prevent Stockfish to compute this position again
      this.props.setParentState({
        has_computed_tip: true,
      });
    }
  };

  callAI = () => {
    // Call AI after user makes a move
    if (
      this.props.game.isGameOver() === false && // Check if the game is over
      this.props.game_result === '' && // Check if player has resigned or out of time
      this.props.game.turn() !== this.props.player_color && // AI's turn
      this.props.player_can_move === false && // player has moved
      this.props.has_computed_tip === true // TipGen has computed the current position
    ) {
      // Call AI to move
      console.log('AI is called');
      analyzeBoard(
        this.AI, // Engine
        this.getHistory(), // Game History
        AI_compute_time, // Compute Time in ms
      );
      // Reset the has_computed_tip
      this.props.setParentState({
        has_computed_tip: false,
      });
    }
  };
  // Check if Game Over when player still has time and did not resign
  checkGameResult = () => {
    // Use the game state to check if the game is over
    if (this.props.game.isGameOver()) {
      console.log('Game Over');
      // Make sure the board reflects the end game
      this.props.setParentState({ displayed_board: this.props.game });

      // Assign the results
      if (this.props.game.isCheckmate()) {
        // White lose = -1
        this.props.setParentState({
          game_result: this.props.game.turn() === 'w' ? -1 : 1,
          player_can_move: false,
        });
      } else {
        // Draw = 0
        this.props.setParentState({
          game_result: 0,
          player_can_move: false,
        });
      }
    }
  };

  // Define Legal Move
  makeAMove = (from, to, promotion, move_party_color) => {
    // Check if Player's Turn
    if (this.props.game.turn() !== move_party_color) {
      return null;
    }
    // Check if game ends
    if (this.props.game_result !== '') {
      return null;
    }
    // Else
    const gameCopy = cloneDeep(this.props.game);
    const result = gameCopy.move({
      from: from,
      to: to,
      promotion: promotion,
    });
    this.props.setParentState({ game: gameCopy });

    // Sync board when it is the player's move
    if (move_party_color === this.props.player_color) {
      this.props.setParentState({ displayed_board: gameCopy }); // Note this is the gamecopy
    }

    return result; // null if the move was illegal, the move object if the move was legal
  };

  // Define User Move
  isDraggablePiece = (piece, sourceSquare) => {
    const piece_color = piece.piece[0];
    if (
      (this.props.game_result !== '') |
      (this.props.player_can_move === false) |
      (this.props.game.turn() !== this.props.player_color) |
      (piece_color !== this.props.player_color)
    ) {
      return false;
    } else {
      return true;
    }
  };

  onDrop = (sourceSquare, targetSquare) => {
    const move = this.makeAMove(
      sourceSquare, // from
      targetSquare, // to
      // TODO allow user to change promotion
      'q', // promotion
      this.props.player_color, // move party color
    );

    // illegal move
    if (move === null) {
      return false;
    }

    if (move !== null) {
      // Turn of feedback
      if (this.props.tip_flag === true) {
        this.props.setParentState({
          show_feedback: false,
        });
      }
      // Record Gameplay data after the player's move
      // Data comes from the state
      const player_move = move.from + move.to; // the last move = the player's move
      const current_game_data = [
        this.getHistory() + ' ' + player_move, // Game board history
        player_move, // the player's move
        this.props.player_time_left_when_move_begins - this.props.player_time_left, // time spent by the player
        this.props.tip_flag, // Tip Received
        player_move === this.props.tip_bestmove, // whether tip is followed
        this.props.tip_bestmove, // best move suggested by TipGen
        this.props.cp_diff, // cp diff
        this.props.first_pos_cp, // first position value
        this.props.second_pos_cp, // second position value
      ];
      let game_data = cloneDeep(this.props.gameplay_data);
      game_data.push(current_game_data);

      // End player's turn and update game data
      this.props.setParentState({
        gameplay_data: game_data,
        player_can_move: false,
        player_time_left: this.props.player_time_left + this.props.player_bonus_time,
        player_time_left_when_move_begins:
          this.props.player_time_left + this.props.player_bonus_time,
        last_player_move: player_move,
        move: move,
        time_spent: this.props.player_time_left_when_move_begins - this.props.player_time_left,
      });

      // Set show_feedback to true if tip_flag is true
      if (this.props.tip_flag === true) {
        this.props.setParentState({
          show_feedback: true,
        });
      }
      // Reset Idle Time
      this.props.setParentState({ last_active_date: new Date().getTime() });
      return true;
    }
  };

  // Collect Game History
  getHistory = () => {
    var game_history = '';
    var history_obj = this.props.game.history({ verbose: true });

    for (var i = 0; i < history_obj.length; ++i) {
      var move = history_obj[i];
      game_history += ' ' + move.from + move.to + (move.promotion ? move.promotion : '');
    }

    return game_history;
  };

  // Parse onMessage Info and Return AI bestmove
  makeAIBestMove = async listener_feedback => {
    var line;

    if (listener_feedback && typeof listener_feedback === 'object') {
      line = listener_feedback.data;
    } else {
      line = listener_feedback;
    }

    // Output Engine Information
    //console.log('AI: ' + line);

    /// Extract the bestmove info from lines of "info depth ..."
    var bestmove = line.match(/^bestmove ([a-h][1-8])([a-h][1-8])([qrbn])?/);
    if (bestmove) {
      if (AI_delay === true) {
        await setTimeout(AI_delay_time);
      }

      this.makeAMove(
        bestmove[1], // from
        bestmove[2], // to
        bestmove[3], // promotion
        this.props.player_color === 'w' ? 'b' : 'w', // move_party color
      );
      const bm = bestmove[1] + bestmove[2];
      this.props.setParentState({
        last_ai_move: bestmove[3] === undefined ? bm : bm + bestmove[3],
      });
    }
  };

  // Parse TipGen info
  checkTip = listener_feedback => {
    var line;

    if (listener_feedback && typeof listener_feedback === 'object') {
      line = listener_feedback.data;
    } else {
      line = listener_feedback;
    }

    // Output Engine Information
    //console.log('TipGen: ' + line);

    /// Extract MultiPV info
    const match = line.match(/\bscore (\w+) (-?\d+)/);
    if (match !== null) {
      // Regex for score values, check whether exists
      var pv_line = line.match(/\bmultipv (-?\d+)/);
      var pv = parseInt(pv_line[1]);
      var score = parseInt(match[2]);

      // To make sure that the caught info is from the bottom
      // we need to delete previous lines when a new set of info is caught
      // i.e., if a multipv = 1 is received, it means a new set of info is coming
      // then reset score_list
      if (pv === 1) {
        this.score_list = [null, null];
      }

      /// Cp
      if (match[1] === 'cp') {
        this.score_list[pv - 1] = score; // location in score_list represent its pv id
        /// Or Mate
      } else if (match[1] === 'mate') {
        this.score_list[pv - 1] = Infinity * score; // score determines + or - infinity
      }
    }

    // Last line is the bestmove line
    var tip_last_line = line.match(/bestmove ([a-h][1-8][a-h][1-8])([qrbn])?/);
    if (tip_last_line !== null) {
      // Return answer
      const tip_flag = tip_criteria(this.score_list, first_second_diff, trivial_cutoff);

      //tip_flag = Math.random() > 0.5
      var tip_bestmove = tip_last_line[1];
      //var tip_promotion = tip_last_line[2]

      // Convert to SAN
      let converter = cloneDeep(this.props.game);
      let simulated_move = converter.move({
        from: tip_bestmove.slice(0, 2),
        to: tip_bestmove.slice(2, 4),
        promotion: 'q',
      });

      // Record cp diff and first and second position value
      let cp_diff = this.score_list.length >= 2 ? this.score_list[0] - this.score_list[1] : null;
      let first_pos_cp = this.score_list[0];
      let second_pos_cp = this.score_list.length >= 2 ? this.score_list[1] : null;

      this.props.setParentState({
        tip_flag: tip_flag,
        tip_bestmove: tip_bestmove,
        tip_bestmove_san: simulated_move.san,
        player_can_move: true,
        displayed_board: this.props.game,
        last_player_move: '',
        show_feedback: false,
        cp_diff: cp_diff,
        first_pos_cp: first_pos_cp,
        second_pos_cp: second_pos_cp,
      });
    }
  };

  // Resign or Out of Time:
  // Mannually set Game Over
  playerGameOver = () => {
    this.props.setParentState({
      game_result: this.props.player_color === 'w' ? -1 : 1,
      player_can_move: false,
    });
  };

  // Resign:
  resignAttempt = () => {
    this.props.setParentState({ attempt_resign: true });
  };

  // Render
  render() {
    if (this.props.assigned_group === -1) {
      return <div></div>;
    }

    return (
      <React.Fragment>
        <div>
          <div className="row">
            <Chessboard
              id="Board"
              position={this.props.displayed_board.fen()}
              onPieceDrop={this.onDrop}
              isDraggablePiece={this.isDraggablePiece}
              boardOrientation={this.props.player_color === 'w' ? 'white' : 'black'}
              boardWidth={this.props.window_height}
              snapToCursor={true}
              areArrowsAllowed={false}
            />
          </div>
          <div className="row">
            <div className="col">
              <button
                type="button"
                onClick={this.resignAttempt}
                className="btn btn-warning mt-2"
                id="resign_attempt"
              >
                Resign
              </button>
            </div>
            <div className="col">
              <ChessClock {...this.props} setParentState={this.props.setParentState} />
            </div>
          </div>
        </div>

        <ResignConfirmation
          {...this.props}
          setParentState={this.props.setParentState}
          initialState={this.props.initialState}
          playerGameOver={this.playerGameOver}
        />
      </React.Fragment>
    );
  }
}

export default ChessBoardUI;
