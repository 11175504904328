import React from 'react';
import App from './components/app';
import { createRoot } from 'react-dom/client';

import '../src/styles/globals.css';

const rootElement = document.getElementById('root');

createRoot(rootElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
