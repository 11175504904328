import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { Spinner } from 'react-bootstrap';

import { updateProfile } from './api/updateProfile';
import { getChangedValues } from '../../utils/getChangedValues';

const profileFields = [
  { label: 'First Name', name: 'firstName' },
  { label: 'Last Name', name: 'lastName' },
  { label: 'Username', name: 'username' },
  { label: 'Email', name: 'email', disabled: true },
  { label: 'Country', name: 'country' },
  { label: 'ELO', name: 'elo', disabled: true },
  { label: 'Location', name: 'location' },
  { label: 'Link to FIDE', name: 'linkToFIDE', disabled: true },
];

export const ProfileTab = ({ isProfileLoading, profileData, handleLogout, token }) => {
  const EditProfileSchema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    username: Yup.string(),
    country: Yup.string(),
    location: Yup.string(),
  });

  const { mutateAsync, isLoading } = useMutation(formData => updateProfile(formData, token));

  const initialValues = {
    firstName: profileData?.[0].first_name || '',
    lastName: profileData?.[0].last_name || '',
    username: profileData?.[0].display_name,
    email: profileData?.[0].email || '',
    elo: profileData?.[0].elo || '',
    country: profileData?.[0].country || '',
    location: profileData?.[0].location || '',
    linkToFIDE: profileData?.[0].fide_link || '',
  };

  const handleSubmit = values => {
    const changedValues = getChangedValues(values, initialValues);

    mutateAsync({
      first_name: changedValues.firstName,
      last_name: changedValues.lastName,
      country: changedValues.country,
      location: changedValues.location,
      display_name: changedValues.username,
    }).catch(error => console.log('Error', error));
  };

  if (isProfileLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container max-w-md mx-auto p-3">
      <Formik
        initialValues={initialValues}
        validationSchema={EditProfileSchema}
        onSubmit={handleSubmit}
      >
        <Form className="space-y-6">
          <div className="row col-sm-12 col-lg-9 space-y-8">
            {profileFields?.map((field, index) => (
              <div
                className="col-12 col-md-6 mb-3 position-relative"
                key={`${field.name}-${index}`}
              >
                <label htmlFor={field.name} className="form-label mt-3">
                  {field.label}
                </label>
                <Field
                  type="text"
                  name={field.name}
                  className="form-control"
                  disabled={field.disabled}
                />
                <div className="position-absolute start-16">
                  <ErrorMessage name={field.name} component="p" className="text-danger fs-6" />
                </div>
              </div>
            ))}
          </div>
          <div className="d-grid gap-2 col-3 ">
            <button
              type="submit"
              className="btn btn-outline-primary mt-3 position-relative px-5 d-flex justify-content-center align-items-center"
              disabled={isLoading}
            >
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm  position-absolute mx-3 end-0 m-6 top-10"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Save Changes
            </button>
            <button type="button" className="btn btn-outline-danger mt-3" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </Form>
      </Formik>
      <div className="d-flex justify-content-center align-items-center pt-5">
        <p className="text-center" style={{ width: 500 }}>
          If you encounter any problems or have questions about the experiment please email us at{' '}
          <a href="mailto: tipsychess23@gmail.com">tipsychess23@gmail.com</a>
        </p>
      </div>
    </div>
  );
};
