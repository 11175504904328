import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { saveHistoryGame } from './api/saveHistoryGame';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

// Game Over Modal
const GameOverModal = ({ isGameOver, handleClose, gameResult, startNewGame }) => {
  const whiteWin = 'Game Over: White won.';
  const blackWin = 'Game Over: Black won.';
  const draw = 'Game Over: Draw.';
  let gameMsg1 = '';

  if (gameResult === 1) {
    gameMsg1 = whiteWin;
  } else if (gameResult === -1) {
    gameMsg1 = blackWin;
  } else {
    gameMsg1 = draw;
  }

  const gameMsg2 = 'You can try another game by clicking the button below.';
  const gameMsg3 = 'See how many tips you have found and download your games!';

  return (
    <>
      <Modal
        show={isGameOver}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Game Ended</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fs-5">{gameMsg1}</p>
          <p>{gameMsg2}</p>

          <div className="text-center my-3">
            <Button style={{ width: 250 }} variant="primary" id="next_game" onClick={startNewGame}>
              Begin Next Game Immediately
            </Button>
            <hr />
            <p>{gameMsg3}</p>
            <Button style={{ width: 250 }} variant="outline-primary" onClick={handleClose}>
              Game History
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

function ResignConfirmation(props) {
  const {
    game_result,
    game_id,
    tips_found_count,
    tips_received_count,
    last_active_date,
    setParentState,
    playerGameOver,
    attempt_resign,
    token,
    displayed_board,
    user,
    player_color,
    player_bonus_time,
    selected_tips,
    moves_details,
    last_player_move,
    tip_bestmove,
    move,
    time_spent,
    tip_flag,
    cp_diff,
    first_pos_cp,
    second_pos_cp,
  } = props;

  const moves_count = displayed_board?.pgn({ maxWidth: 5, newline: '|' }).split('|').length;

  const [isGameOver, setIsGameOver] = useState(game_result !== '');
  const navigate = useNavigate();

  const { mutateAsync } = useMutation(saveHistoryGame);

  const movesDetails = useMemo(() => moves_details || [], [moves_details]);

  const statisticOfGame = useMemo(() => {
    return {
      user: user?.email,
      game_id: game_id,
      event: 'game',
      site: window.location.origin,
      date: format(new Date(last_active_date), 'yyyy-MM-dd'),
      white: player_color === 'w' ? user?.display_name || user?.email : 'Tipsychess AI',
      black: player_color === 'w' ? 'Tipsychess AI' : user?.display_name || user?.email,
      result: game_result === 1 ? 1 : game_result === -1 ? 0 : 0.5,
      tips_found_percent: (tips_found_count / tips_received_count) * 100 || 0,
      tips_received_count: tips_received_count,
      moves: displayed_board?.pgn() || '',
      moves_count,
      game_time: player_bonus_time === 2 ? `3 + 2` : `${player_bonus_time} + ${player_bonus_time}`,
      selected_tips:
        selected_tips === 5 ? 'Full tips' : selected_tips === 3 ? 'Minimal tips' : 'No tips',
      moves_details,
    };
  }, [
    user?.email,
    user?.display_name,
    game_id,
    last_active_date,
    player_color,
    game_result,
    tips_found_count,
    tips_received_count,
    displayed_board,
    moves_count,
    player_bonus_time,
    selected_tips,
    moves_details,
  ]);

  const moveDetail = useMemo(() => {
    return {
      id: game_id,
      move_from: move.from,
      move_to: move.to,
      move_order_number: moves_count,
      move_time_spend: time_spent,
      tip_received: tip_flag,
      tip_followed: last_player_move === tip_bestmove,
      best_move: tip_bestmove || 'N/A',
      cp_diff: cp_diff ?? '',
      first_pos_cp: first_pos_cp ?? '',
      second_pos_cp: second_pos_cp ?? '',
    };
  }, [
    game_id,
    last_player_move,
    move.from,
    move.to,
    moves_count,
    time_spent,
    tip_bestmove,
    tip_flag,
    cp_diff,
    first_pos_cp,
    second_pos_cp,
  ]);

  const handleClose = () => {
    navigate('/profile?selectedTab=1', { replace: true });
    setIsGameOver(false);
    window.location.reload();
  };

  const startNewGame = () => {
    setIsGameOver(false);
    window.location.reload();
  };

  const confirmResign = () => {
    setParentState({ attempt_resign: false });
    playerGameOver();
  };

  const cancelResign = () => {
    setParentState({ attempt_resign: false });
  };

  const sandDateGame = useCallback(async () => {
    try {
      await mutateAsync({ token, statisticOfGame });
    } catch (error) {
      console.log('%c  error', error);
    }
  }, [mutateAsync, statisticOfGame, token]);

  useEffect(() => {
    if (last_player_move !== '') {
      movesDetails.push(moveDetail);
      setParentState({ moves_details: movesDetails });
    }
  }, [last_player_move, moveDetail, movesDetails, setParentState]);

  useEffect(() => {
    if (game_result !== '' && !isGameOver) {
      setIsGameOver(true);
      sandDateGame();
    }
  }, [isGameOver, game_result, sandDateGame]);

  return (
    <>
      <Modal
        show={attempt_resign}
        onHide={cancelResign}
        keyboard={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Resign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fs-5">Are you sure you want to resign?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" id="confirm_resign" onClick={confirmResign}>
            Yes, I Resign
          </Button>
          <Button variant="primary" onClick={cancelResign}>
            No, Continue the Current Game
          </Button>
        </Modal.Footer>
      </Modal>
      <GameOverModal
        gameResult={game_result}
        isGameOver={isGameOver}
        handleClose={handleClose}
        startNewGame={startNewGame}
      />
    </>
  );
}

export { ResignConfirmation };
