import { createBrowserRouter } from 'react-router-dom';

import Mainpage from '../../components/mainPage/main';
import { PlayPage, ProfilePage, ResetPasswordPage } from '../../pages';
import TutorialPage from '../../components/tutorial/tutorialMain';

export const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <div>
        <Mainpage />
      </div>
    ),
  },
  {
    path: 'play',
    element: (
      <div>
        <PlayPage />
      </div>
    ),
  },
  {
    path: 'tutorial',
    element: (
      <div>
        <TutorialPage />
      </div>
    ),
  },
  {
    path: 'profile',
    element: <ProfilePage />,
  },
  {
    path: 'reset-password-confirm',
    element: <ResetPasswordPage />,
  },
]);
