import React from 'react';

function GameHistoryBoard(props) {
/* 	const [displayed_pgn, updatePGN] = useState([]);


	useEffect(() => {
		if (props.game.turn() !== props.player_color) {
			updatePGN(props.displayed_board.pgn({ maxWidth: 5, newline: '|' }).split('|'));
		}

		if (props.game.turn() === props.player_color && props.player_can_move === true) {
			updatePGN(props.displayed_board.pgn({ maxWidth: 5, newline: '|' }).split('|'));
		}

	}, [props.game, props.displayed_board, props.player_can_move]); */

	if (props.assigned_group === -1) {
		return <div></div>;
	}

	if (props.game.pgn().length === 0) {
		return (
			<React.Fragment>
				<div id="last_move" style={{ display: 'none' }}>
					{props.last_ai_move}
				</div>
				<div id="game_fen" style={{ display: 'none' }}>
					{props.displayed_board.fen()}
				</div>
				<div className="fs-3 mb-2">Game History:</div>
			</React.Fragment>
		);
	} else {
		return (
			<div>
				<div id="last_move" style={{ display: 'none' }}>
					{props.last_ai_move}
				</div>
				<div id="game_fen" style={{ display: 'none' }}>
					{props.displayed_board.fen()}
				</div>
				<div className="fs-3 mb-2">Game History:</div>
				<div
					style={{
						overflowY: 'auto',
						height: props.window_height * 0.6,
					}}
				>
					<table>
						<tbody id="pgn">
							{props.displayed_board.pgn({ maxWidth: 5, newline: '|' }).split('|').map((turn, index) => {
								return (
									<tr key={index}>
										<td>{turn}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default GameHistoryBoard;
