import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import { useValidation } from '../../hooks';
import forgotPassword from '../../service/auth/api/forgotPassword';

export const ForgotPasswordForm = ({ email, setIsForgotPassword }) => {
  const { mutateAsync, isLoading } = useMutation(forgotPassword);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const { emailValidation } = useValidation();
  const navigate = useNavigate();

  const AuthSchema = Yup.object().shape({
    email: emailValidation,
  });

  const handleSignIn = async values => {
    setErrorMessage('');
    try {
      const data = await mutateAsync({
        email: values.email,
      });
      if (data.status === 204 || data.status === 201) {
        setIsSuccess(true);
      }
    } catch (error) {
      setErrorMessage('Invalid email');
    }
  };

  const handleForgotPassword = () => {
    setIsForgotPassword(false);
  };

  const goHome = () => navigate('/');

  return isSuccess ? (
    <>
      <div className="fs-4 text-center fw-bolder">Password Reset Successful!</div>
      <hr />
      <p className="text-center">
        Your password has been successfully reset. To continue using our service, please check your
        email for the reset link and log in with your new password.
      </p>
      <div className="d-flex justify-content-center gap-3 mx-5 mb-3 mt-5">
        <Button
          variant="primary"
          onClick={goHome}
          className=" position-relative px-5 d-flex justify-content-center align-items-center"
        >
          To the Home Page
        </Button>
      </div>
    </>
  ) : (
    <>
      <div className="fs-4 text-center fw-bolder">Forgot password?</div>
      <hr />
      <p className="text-center">You can reset your password here</p>
      <Formik
        initialValues={{ email: email || '', password: '' }}
        validationSchema={AuthSchema}
        onSubmit={handleSignIn}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Field type="email" name="email" placeholder="Enter email" className="form-control" />
              <div className="position-absolute start-16">
                <ErrorMessage name="email" component="div" className="text-danger" />
              </div>
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            <div className="d-flex justify-content-center gap-3 mx-5 mb-3 mt-5">
              <Button
                variant="primary"
                type="submit"
                className=" position-relative px-5 d-flex justify-content-center align-items-center"
              >
                Reset
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm  position-absolute mx-3 end-0 m-6 top-10"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
              <Button
                variant="danger"
                onClick={handleForgotPassword}
                className=" position-relative px-5 d-flex justify-content-center align-items-center"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
