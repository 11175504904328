import { useState } from 'react';

const Tab = ({ id, label, isActive, onClick }) => (
  <button
    className={`btn ${isActive ? 'nav-link active ' : 'nav-link'} rounded-0`}
    id={`${id}-tab`}
    data-bs-toggle="tab"
    type="button"
    role="tab"
    aria-controls={id}
    aria-selected={isActive}
    onClick={() => onClick(id)}
  >
    {label}
  </button>
);

const TabContent = ({ id, isActive, children }) => (
  <div
    className={`tab-pane fade ${isActive ? 'show active' : ''}`}
    id={id}
    role="tabpanel"
    aria-labelledby={`${id}-tab`}
  >
    {children}
  </div>
);

export const Tabs = ({ tabs, selectedTab }) => {
  const [activeTab, setActiveTab] = useState(tabs[selectedTab || 0].id);

  const handleTabClick = tabId => {
    setActiveTab(tabId);
  };

  return (
    <div className="w-100">
      <ul className="nav nav-tabs" role="tablist">
        {tabs.map(tab => (
          <li key={tab.id} className="nav-item" role="presentation">
            <Tab
              id={tab.id}
              label={tab.label}
              isActive={activeTab === tab.id}
              onClick={handleTabClick}
            />
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {tabs.map(tab => (
          <TabContent key={tab.id} id={tab.id} isActive={activeTab === tab.id}>
            {tab.content}
          </TabContent>
        ))}
      </div>
    </div>
  );
};
