import React from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { format } from 'date-fns';

import { useHistoryGame } from './api/useHistoryGame';
import { getPgnFile } from './api/getPgnFile';

export const HistoryTab = ({ isProfileLoading, profileData, token }) => {
  const { data: historyGameData, isLoading } = useHistoryGame(token);

  const downloadPGN = async id => {
    try {
      const fileData = await getPgnFile(token, id);

      const blob = new Blob([fileData], { type: 'application/octet-stream' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `game_${id}.PGN`;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading PGN file:', error);
    }
  };

  if (isProfileLoading || isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <Spinner animation="border" variant="primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  let totalResult = 0;
  let totalTipsFoundPercent = 0;
  let totalGamesWithMinimalTips = 0;

  historyGameData?.forEach(game => {
    totalResult += +game.result;
    if (game.selected_tips === 'Minimal tips' && game.tips_found_percent !== 0) {
      totalTipsFoundPercent += game.tips_found_percent;
      totalGamesWithMinimalTips++;
    }
  });

  const averageResult = historyGameData?.length > 0 ? totalResult / historyGameData?.length : 0;
  const averageTipsFoundPercent =
    totalGamesWithMinimalTips > 0 ? totalTipsFoundPercent / totalGamesWithMinimalTips : 'N/A';

  return (
    <div className="pt-4">
      <h3 className="pb-3">Completed Games</h3>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th style={{ minWidth: '150px' }}>Player</th>
            <th>Game Type</th>
            <th>Result</th>
            <th>Time</th>
            <th style={{ maxWidth: '110px' }}>% of Tips Found</th>
            <th style={{ maxWidth: '110px' }}># of Tips Received</th>
            <th>Moves</th>
            <th>Date</th>
            <th className="text-center" style={{ maxWidth: '80px' }}>
              Download Game
            </th>
          </tr>
        </thead>
        <tbody>
          {!isProfileLoading && !isLoading
            ? historyGameData?.map((game, index) => (
                <tr key={`${index}-${game.game_id}`}>
                  <td>
                    {profileData?.[0]?.display_name} ({profileData?.[0]?.elo})
                  </td>
                  <td>{game.selected_tips}</td>
                  <td>{game.result}</td>
                  <td>{game.game_time}</td>
                  <td>
                    {game.selected_tips === 'Minimal tips' && game.tips_found_percent !== 0
                      ? `${game.tips_found_percent.toFixed(1)}%`
                      : 'N/A'}
                  </td>
                  <td>{game.selected_tips !== 'No tips' ? game.tips_received_count : 'N/A'}</td>
                  <td>{game.moves_count}</td>
                  <td>{format(new Date(game.date), 'MMM dd, yyyy')}</td>
                  <td className="text-center">
                    <button className="btn btn-link" onClick={() => downloadPGN(game.game_id)}>
                      <span className="bi bi-cloud-download"></span>
                    </button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
        {historyGameData?.length ? (
          <tfoot>
            <tr>
              <td className="d-flex flex-column ">
                <span className="fw-bold">Average</span>
                <span className="fw-bold">result</span>
              </td>
              <td></td>
              <td>{averageResult?.toFixed(1)}</td>
              <td></td>
              <td>
                {typeof averageTipsFoundPercent === 'number'
                  ? averageTipsFoundPercent.toFixed(1)
                  : 0}
                %
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tfoot>
        ) : null}
      </Table>
      {!historyGameData?.length && !isProfileLoading && !isLoading && (
        <p className="pb-3 text-center">No games found</p>
      )}
    </div>
  );
};
