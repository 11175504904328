import axios from 'axios';

export const saveHistoryGame = async ({ token: authToken, statisticOfGame: formData }) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API}api/v1/games/`, formData, {
      body: formData,
      headers: {
        Authorization: `Token ${authToken}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
