import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

const group_to_player_param = {
  1: { player_color: 'w', assigned_group: '1' },
  3: { player_color: 'w', assigned_group: '2' },
  5: { player_color: 'w', assigned_group: '3' },
};

function GameBeginConfirmation(props) {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const navigate = useNavigate();

  const handleTimeChange = option => {
    setSelectedTime(prev => (prev === option ? '' : option));
  };

  const handleOptionChange = option => {
    setSelectedOption(prev => (prev === option ? '' : option));
  };

  const disabledStart = !selectedOption || !selectedTime;

  const getSelectedTime = () => {
    if (selectedTime === '3') {
      return 180;
    }
    if (selectedTime === '5') {
      return 300;
    }
    if (selectedTime === '10') {
      return 600;
    }
    if (selectedTime === '15') {
      return 900;
    }
  };

  const goHome = () => navigate('/');

  return (
    <Modal
      show={props.game_begin === false}
      onHide={goHome}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Modal.Header closeButton />
      <Modal.Body className="text-center">
        <div className="fs-4 text-center fw-bolder">Ready to Start the Game?</div>
        <hr />
        <div className="pt-3 col-11 mx-auto ">
          <p className="fw-bold text-decoration-underline">
            you can play only via desktop or laptop
          </p>
          <h4 className="text-info mt-5">Try to play using our AI tips!</h4>
          <h4>You can play with (a) full tips, (b) minimal tips, or (c) no tips .</h4>
          <p>(for info on our tips, see our Tutorial)</p>
          <h4>
            Play as many games as you like, trying each of our tips and see which one improves your
            game the most!
          </h4>
          <h4 className="mt-5"> Select the tip you want to play your next game with:</h4>
        </div>
        <div className="d-flex justify-content-between col-8 mx-auto mt-3">
          <Button
            className={`tips-button ${selectedOption === 5 ? 'selected' : ''}`}
            onClick={() => handleOptionChange(5)}
          >
            <span className="fw-bolder">Full</span> tips
          </Button>

          <Button
            className={`tips-button ${selectedOption === 3 ? 'selected' : ''}`}
            onClick={() => handleOptionChange(3)}
          >
            <span className="fw-bolder">Minimal</span> tips
          </Button>

          <Button
            className={`tips-button ${selectedOption === 1 ? 'selected' : ''}`}
            onClick={() => handleOptionChange(1)}
          >
            <span className="fw-bolder">No</span> tips
          </Button>
        </div>

        <h4 className="mt-5 "> Select your game`s time (min + sec)</h4>
        <div className="d-flex justify-content-between col-10 mx-auto mt-3">
          <Button
            className={`time-button ${
              selectedTime === '3' ? 'time-button-selected ' : ''
            } fw-bolder`}
            onClick={() => handleTimeChange('3')}
          >
            3+2
          </Button>

          <Button
            className={`time-button ${
              selectedTime === '5' ? 'time-button-selected ' : ''
            } fw-bolder`}
            onClick={() => handleTimeChange('5')}
          >
            5+5
          </Button>

          <Button
            className={`time-button ${
              selectedTime === '10' ? 'time-button-selected ' : ''
            } fw-bolder`}
            onClick={() => handleTimeChange('10')}
          >
            10+10
          </Button>
          <Button
            type={null}
            className={`time-button ${
              selectedTime === '15' ? 'time-button-selected ' : ''
            } fw-bolder`}
            onClick={() => handleTimeChange('15')}
          >
            15+15
          </Button>
        </div>

        <div className="d-flex justify-content-center align-items-center gap-5">
          <Button
            className="mt-5 mb-3"
            variant="primary"
            id="begin_first_game"
            disabled={disabledStart}
            style={{ width: '150px' }}
            onClick={() => {
              props.setParentState({
                game_begin: true,
                player_time_left_when_move_begins: getSelectedTime(),
                player_bonus_time: selectedTime === '3' ? 2 : +selectedTime,
                player_time_left: getSelectedTime(),
                player_color: group_to_player_param[selectedOption]['player_color'],
                assigned_group: group_to_player_param[selectedOption]['assigned_group'],
                selected_tips: selectedOption,
              });
            }}
          >
            Start Game
          </Button>
          <Button
            variant="danger"
            type="button"
            onClick={goHome}
            className="mt-5 mb-3"
            style={{ width: '150px' }}
          >
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default GameBeginConfirmation;
