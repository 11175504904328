import React, { Component } from 'react';

class TipBoard extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.last_player_move !== this.props.last_player_move &&
      this.props.prev_bestmove !== this.props.tip_bestmove &&
      this.props.tip_flag
    ) {
      this.props.setParentState({
        tips_received_count: this.props.tips_received_count + 1,
        prev_bestmove: this.props.tip_bestmove,
      });
    }

    if (
      prevProps.last_player_move !== this.props.last_player_move &&
      this.props.last_player_move === this.props.tip_bestmove &&
      this.props.tip_flag
    ) {
      this.props.setParentState({
        tips_found_count: this.props.tips_found_count + 1,
      });
    }
  }

  boardInfo = ({
    show_at_all,
    tip_visibility,
    tip_bestmove,
    best_tip_avail,
    tip_bestmove_san,
    player_move,
    show_feedback,
    isFullTip,
  }) => {
    // Show whether the player has found the optimal move
    let msg = 'Try to find the optimal move';
    if (player_move === '') {
      msg = 'Try to find the optimal move';
    } else if (player_move === tip_bestmove) {
      msg = 'You found the optimal move!';
    } else if (isFullTip) {
      msg = (
        <span>
          <strong>{tip_bestmove_san}</strong> seems to be the best move
        </span>
      );
    } else {
      msg = (
        <span>
          You didn't find the optimal move. <strong>{tip_bestmove_san}</strong> was the best move
        </span>
      );
    }
    //const msg = tip_followed === true ? "You found the optimal move!" : "You didn't find the optimal move. " + tip_bestmove + " was the best move"
    //console.log("show_feedback_value", show_feedback)
    //console.log("player move", player_move)

    return (
      <div className="" style={{ width: this.props.window_height, visibility: show_at_all }}>
        <div className="fs-3">Tip Board:</div>
        <div
          className="mb-3"
          style={{
            backgroundColor: 'rgb(0,0,0,0.1)',
          }}
        >
          <div className="pt-1	" style={{ visibility: tip_visibility }}>
            <div className="fs-4 text-center d-flex justify-content-center align-items-center gap-3">
              There is an optimal move here!
            </div>
            <div
              className="fs-4 text-center pb-1"
              id="tip_move"
              style={{ visibility: tip_visibility, display: best_tip_avail }}
            >
              {msg}
            </div>
          </div>
        </div>
        {!isFullTip && (
          <div
            className="mb-3 mt-2"
            style={{
              backgroundColor: 'rgb(0,0,0,0.1)',
            }}
          >
            <div
              className="fs-5 fw-light text-center pb-1"
              style={{
                visibility: show_feedback === true ? 'visible' : 'hidden',
                display: 'block',
              }}
            >
              {msg}
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    if (parseInt(this.props.assigned_group) === 1) {
      return this.boardInfo({
        show_at_all: 'hidden',
        tip_visibility: 'hidden',
        tip_bestmove: '',
        best_tip_avail: 'none',
      });
    }

    if (parseInt(this.props.assigned_group) === 2) {
      return this.boardInfo({
        show_at_all: 'visible',
        tip_bestmove: this.props.tip_bestmove,
        tip_visibility: this.props.tip_flag === true ? 'visible' : 'hidden',
        best_tip_avail: 'none',
        tip_followed:
          this.props.last_player_move === ''
            ? false
            : this.props.last_player_move == this.props.tip_bestmove,
        player_move: this.props.last_player_move,
        tip_bestmove_san: this.props.tip_bestmove_san,
        show_feedback: this.props.show_feedback,
      });
    }

    if (parseInt(this.props.assigned_group) === 3) {
      return this.boardInfo({
        show_at_all: 'visible',
        tip_bestmove: this.props.tip_bestmove_san,
        tip_bestmove_san: this.props.tip_bestmove_san,
        tip_visibility: this.props.tip_flag === true ? 'visible' : 'hidden',
        best_tip_avail: 'block',
        isFullTip: true,
      });
    }
  }
}

export default TipBoard;
