import axios from 'axios';

const signUp = async formData => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API}api/v1/auth/users/`, formData);
    return response?.data;
  } catch (error) {
    return error;
  }
};

export default signUp;
