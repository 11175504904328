import axios from 'axios';

const signIn = async formData => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API}auth/token/login`, formData);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export default signIn;
