import React, { Component } from 'react';

class TipBoard extends Component {
	boardInfo = ({
		show_at_all,
		tip_visibility,
		tip_bestmove,
		best_tip_avail,
	}) => {
		return (
			<div className="" style={{ width: this.props.window_height, visibility: show_at_all}}>
				<div className="fs-3">Tip Board:</div>
				<div
					className="mb-3"
					style={{
						backgroundColor: 'rgb(0,0,0,0.1)',
					}}
				>
					<div
						className="pt-1	"
						style={{ visibility: tip_visibility }}
					>
						<div className="fs-4 text-center">
							There is an optimal move here!
						</div>
						<div
							className="fs-4 text-center pb-1"
							id="tip_move"
							style={{ visibility: tip_visibility, display: best_tip_avail }}
						>
							<span className='fs-2 fw-bold'>{tip_bestmove}</span> seems to be the best move
						</div>
					</div>
				</div>
				<div className='mb-3'> Our AI is telling you that a unique, optimal move exists in this position! Thus, it is crucial that you find it. In the board below play the move you would play if this position happened in one of your games.</div>
			</div>
		);
	};


	render() {
			return this.boardInfo({
				show_at_all: 'visible',
				tip_bestmove: '',
				tip_visibility: true,
				best_tip_avail: "none"
			});
	}
}

export default TipBoard;
