import React, { useState } from 'react';

import TopNavbar from '../navbar';
import { AuthModal } from '../AuthModal';

function LoginPortal() {
  const [show, setShow] = useState(true);

  const onClose = () => setShow(false);

  return (
    <React.Fragment>
      <div>
        <TopNavbar />
      </div>
      <AuthModal isOpen={show} onClose={onClose} />
    </React.Fragment>
  );
}

function PreGamePortal(props) {
  return <LoginPortal {...props} />;
}

export { PreGamePortal };
