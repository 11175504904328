import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function BeginTutorial(props) {
	const [show, setShow] = useState(true);

	const handleClose = () => {
        setShow(false);
        props.setParentState({ready:true})
	};

	return (
		<>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header>
					<Modal.Title>Tutorial</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="fs-5 mb-3">
						To help you understand minimal tips better, a quick
						tutorial follows.
					</div>
					<div className="fs-5 mb-3">
						The positions you will encounter came from other
						participants’ games, where they received minimal tips.
						This could have happened in your game!
					</div>
					<div className="fs-5 mb-3">
						For each position, you will have 1 minute to find the
						unique optimal move.
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="btn btn-success"
						id="next_game"
						onClick={handleClose}
					>
						Start the tutorial
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default BeginTutorial