import axios from 'axios';

export const getPgnFile = async (authToken, id) => {
  if (authToken) {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}api/v1/games/${id}/download`, {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
  throw new Error('Token not found');
};
