import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { SignInForm } from './SignInForm';
import { SignUpForm } from './SignUpForm';
import { ForgotPasswordForm } from './ForgotPasswordForm';

export const AuthModal = ({ isOpen, onClose }) => {
  const [showSignIn, setShowSignIn] = useState(true);
  const [email, setEmail] = useState();
  const [isForgotPassword, setIsForgotPassword] = useState();

  const handleToggleForm = () => {
    setShowSignIn(!showSignIn);
  };

  return (
    <Modal
      show={isOpen}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-auth-vcenter"
      centered
      onHide={onClose}
    >
      <Modal.Body>
        {isForgotPassword ? (
          <ForgotPasswordForm
            setIsForgotPassword={setIsForgotPassword}
            handleToggleForm={handleToggleForm}
          />
        ) : showSignIn ? (
          <SignInForm
            email={email}
            setIsForgotPassword={setIsForgotPassword}
            handleToggleForm={handleToggleForm}
          />
        ) : (
          <SignUpForm
            setShowSignIn={setShowSignIn}
            setEmail={setEmail}
            handleToggleForm={handleToggleForm}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};
