import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import TopNavbar from '../navbar';
import { TutorialPortal } from './tutorialGames/tutorialPortal'

function TutorialPage(props) {
	const [instructionPage, setInstruction] = useState(1);

	const updateInstruction = () => {
		if (instructionPage === 1) {
			return (
				<img
					src="./img/instructions1.svg"
					alt="instruction1"
					style={{ width: '50%' }}
				/>
			);
		}

		if (instructionPage === 2) {
			return (
				<img
					src="./img/instructions2.svg"
					alt="instruction2"
					style={{ width: '50%' }}
				/>
			);
		}

		if (instructionPage === 3) {
			return (
				<img
					src="./img/instructions3.svg"
					alt="instruction3"
					style={{ width: '50%' }}
				/>
			);
		}
	};

	const handleNext = () => {
		setInstruction(instructionPage + 1);
		window.scrollTo(0, 0)
	};

	const handlePrev = () => {
		const prevPage = Math.max(instructionPage - 1, 1);
		setInstruction(prevPage);
		window.scrollTo(0, 0)
	};

	if (instructionPage <= 3) {
		return (
			<>
				<div>
					<TopNavbar />
				</div>
				<div className='container text-center'>
					<div className='mt-2'>
						{updateInstruction()}
					</div>
					<div className='row mb-4'>
						<div className='col'>
							<Button
								id="prev"
								variant="info"
								onClick={handlePrev}
								disabled={instructionPage === 1}
							>
								Previous Page
							</Button>
						</div>
						<div className='col-1'>
							{instructionPage}/3
						</div>
						<div className='col'>
							<Button id="agree" variant="info" onClick={handleNext}>
								{instructionPage < 3
									? 'Next Page'
									: "Let's try a few simple examples"}
							</Button>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return (
			<>
				<div>
				<TopNavbar />
				</div>
				<TutorialPortal />
			</>
			)
		}
	}

export default TutorialPage