import React, { useCallback, useEffect, useReducer } from 'react';
import { PreGamePortal } from '../components/preGame/preGamePortal';
import { Portal } from '../components/game/game_portal';
import { useAuthDispatch, useAuthState } from '../service/store/AuthContext';
import { useMyProfileLazy } from '../components/ProfileTabs/api/useMyProfile';

export const PlayPage = () => {
  const { token } = useAuthState();
  const authDispatch = useAuthDispatch();

  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    AI_elo: -1,
  });

  const { data: profileData, isLoading: isProfileLoading, refetch } = useMyProfileLazy(token, 0);
  const setTopState = props => {
    setState(props);
  };

  const mapEloToAiElo = userElo => {
    const eloMapping = {
      13: 1900,
      14: 1950,
      15: 2000,
      16: 2050,
      17: 2150,
      18: 2200,
      19: 2250,
      20: 2300,
      21: 2400,
      22: 2450,
      23: 2550,
      24: 2650,
      25: 2750,
    };

    for (const eloRange in eloMapping) {
      if (userElo >= parseInt(eloRange, 10)) {
        return eloMapping[eloRange];
      }
    }

    return 1300;
  };

  const registerPlayer = useCallback(playerElo => {
    setTopState({
      AI_elo: mapEloToAiElo(playerElo),
    });
  }, []);

  useEffect(() => {
    if (!isProfileLoading && profileData && !profileData?.status) {
      const playerElo = profileData?.[0]?.elo;

      registerPlayer(playerElo);
    }
  }, [isProfileLoading, profileData, registerPlayer]);

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [refetch, token]);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('authToken');
    authDispatch({ type: 'LOG_OUT' });
  }, [authDispatch]);

  if (profileData?.status) {
    handleLogout();
    return;
  }

  if (!token) {
    return <PreGamePortal />;
  }

  return (
    <Portal
      setTopState={setTopState}
      token={token}
      {...state}
      user={!isProfileLoading ? profileData?.[0] : {}}
    />
  );
};
