import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';

import signUp from '../../service/auth/api/signUp';
import { useValidation } from '../../hooks';

export const SignUpForm = ({ setShowSignIn, setEmail, handleToggleForm }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const navigate = useNavigate();
  const {
    emailValidation,
    eloValidation,
    fideLinkValidation,
    passwordValidation,
    repeatPasswordValidation,
  } = useValidation();

  const AuthSchema = Yup.object().shape({
    email: emailValidation,
    password: passwordValidation,
    repeatPassword: repeatPasswordValidation,
    elo: eloValidation,
    linkToFIDE: fideLinkValidation,
  });

  const { mutateAsync, isLoading } = useMutation(signUp, {
    onSuccess: data => {
      if (data?.email) {
        setErrorMessage('');
        setShowSignIn(true);
        setEmail(data?.email);
      } else {
        setErrorMessage(
          data?.response.data?.email ||
            data?.response.data?.elo ||
            data?.response.data?.linkToFIDE ||
            data?.response.data?.password ||
            data?.message,
        );
      }
    },
  });

  const handleSignUp = async values => {
    setErrorMessage('');
    try {
      await mutateAsync({
        email: values.email,
        password: values.password,
        elo: values.elo,
        fide_link: values.linkToFIDE,
      });
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const goHome = () => navigate('/');

  return (
    <>
      <div className="fs-4 text-center fw-bolder">Sign Up</div>
      <hr />
      <Formik
        initialValues={{ email: '', password: '', repeatPassword: '', elo: '', linkToFIDE: '' }}
        validationSchema={AuthSchema}
        onSubmit={handleSignUp}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Field type="email" name="email" placeholder="Enter email" className="form-control" />
              <ErrorMessage name="email" component="div" className="text-danger" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Password</Form.Label>
              <div className="input-group">
                <Field
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Password"
                  className="form-control"
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <i className="bi bi-eye-slash"></i>
                  ) : (
                    <i className="bi bi-eye"></i>
                  )}
                </button>
              </div>
              <ErrorMessage name="password" component="div" className="text-danger" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="repeatPassword">
              <Form.Label>Repeat Password</Form.Label>
              <div className="input-group">
                <Field
                  type={showRepeatPassword ? 'text' : 'password'}
                  name="repeatPassword"
                  placeholder="Repeat Password"
                  className="form-control"
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                >
                  {showRepeatPassword ? (
                    <i className="bi bi-eye-slash"></i>
                  ) : (
                    <i className="bi bi-eye"></i>
                  )}
                </button>
              </div>
              <ErrorMessage name="repeatPassword" component="div" className="text-danger" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="elo">
              <Form.Label>
                ELO (your standard FIDE elo rating. If unranked -you don't have an elo-, please use
                1000)
              </Form.Label>
              <Field type="text" name="elo" placeholder="Enter ELO" className="form-control" />
              <ErrorMessage name="elo" component="div" className="text-danger" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="elo">
              <Form.Label>
                Link to your FIDE profile (if unranked -you have no elo- use
                https://ratings.fide.com/profile/4234448)
              </Form.Label>
              <Field
                type="text"
                name="linkToFIDE"
                placeholder="Enter link to FIDE"
                className="form-control"
              />
              <ErrorMessage name="linkToFIDE" component="div" className="text-danger" />
            </Form.Group>
            {errorMessage && <p className="text-danger">{errorMessage}</p>}
            <div className="d-flex justify-content-center gap-3">
              <Button
                variant="primary"
                type="submit"
                className=" position-relative px-5 d-flex justify-content-center align-items-center"
              >
                Sign Up
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm  position-absolute mx-3 end-0 m-6 top-10"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
              <Button
                variant="danger"
                type="button"
                onClick={goHome}
                className=" position-relative px-5 d-flex justify-content-center align-items-center"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="text-center mt-3">
        <div className="d-flex justify-content-center align-items-center">
          <p className="mb-0 me-2">Already have an account?</p>
          <button className="btn btn-link p-0" onClick={handleToggleForm}>
            Sign In
          </button>
        </div>
      </div>
    </>
  );
};
