import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";

const successMsg = [
	["This is the unique, optimal move! You found it!",
		"After 1. Rf3, White has a much better position. However, if they have played anything else they would have had a much worse position(no other way to save the Bishop).",
		"Hence, 1. Rf3 is a unique, optimal move in this position, and that's why you would have received a tip in this position if you were playing this game."],
	["This is the unique, optimal move! You found it!",
		"After 1. a6, White has a winning position.However, if they have played anything else they wouldn't have as much of an advantage (only a slightly better position).",
		"Hence, 1. a6 is a unique, optimal move in this position, and that's why you would have received a tip in this position if you were playing this game."],
	["This is the unique, optimal move! You found it!",
		"After 1. ...Rf1, Black has a much better position.However, if they have played anything else (e.g., 1. ... Ra2) they wouldn't have had much of an advantage after 2. Kf3.",
		"Hence, 1. ...Rf1, is a unique, optimal move in this position, and that's why you would have received a tip in this position if you were playing this game.So, note that while most tips are useful during the middle game, there are many cases where unique, optimal moves exist in endings, and so a tip could be very helpful in these positions."],
];
const failureMsg = [
	["You didn't find the unique, optimal move.",
		"White's bishop is hanging, but there is a unique, optimal move to save him!  After 1. Rf3 White has a much better position. They threaten mate in f8 so the Black cannot capture the Bishop. After playing something like 1. ... h6, White can then play 2. Be4 saves the Bishop, forcing the change of Rooks and having a winning position.",
		"Hence, 1. Rf3 is a unique, optimal move in this position, and that's why you would have received a tip in this position if you were playing this game."],
	["You didn't find the unique, optimal move.",
		"After 1. a6 White has a winning position. The pawn at a6 cannot be captured as the Knight in c6 would then be hanging. Also, with a6 White threaten to capture the pawn in b7 and the Knight would then be hanging. After playing something like 1. ... Ne5, White can then play 2. Rd8+ and then 3. Rc7+ where they would have a winning position.",
		"Hence, 1. a6 is a unique, optimal move in this position, and that's why you would have received a tip in this position if you were playing this game."],
	["You didn't find the unique, optimal move.",
		"After 1. ... Rf1, Black has a winning position. They threaten g5 and then Rf4 and White's King cannot protect the g4 pawn. However, if they have played anything else (e.g., 1. ... Ra2) they wouldn't have had much of an advantage after 2. Kf3. Hence, 1. ... Rf1, is a unique, optimal move in this position, and that's why you would have received a tip in this position if you were playing this game.",
		"So, note that while most tips are useful during the middle game, there are many cases where unique, optimal moves exist in endings, and so a tip could be very helpful in these positions."]
]

// Game Over Modal
class TutorialResult extends Component {
	state = {
		gameover_modal_show: this.props.game_result !== '',
	};

	handleClose = () => {
		// Add next game logic
		// Game that is not the last
		if (this.props.game_id < 3) {
			// Next Game Setting
			this.props.nextGame();
			this.setState({ gameover_modal_show: false });
		} else {
			var current_address = window.location.href
			window.location.href = current_address.slice(0,-9) + "/play";
		}
		this.setState({ gameover_modal_show: false });
	};

	componentDidUpdate() {
		if (
			this.props.game_result !== '' &&
			this.state.gameover_modal_show === false
		) {
			this.setState({ gameover_modal_show: true });
		}
	}

	formatModal = () => {
		const game_result = this.props.game_result;
		let gameMsg = ""
		let buttonMsg = ""

		if (game_result === true) {
			gameMsg = successMsg[this.props.game_id - 1]
		} else {
			gameMsg = failureMsg[this.props.game_id - 1];
		}

		if (this.props.game_id < 3) {
			buttonMsg = 'Next Tutorial';
		} else {
			buttonMsg = "You are ready! Let's start a game!";
		}

		return (
			<>
				<Modal
					show={this.state.gameover_modal_show}
					onHide={this.handleClose}
					keyboard={false}
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header>
						<Modal.Title>
							Tutorial {this.props.game_id} Ended
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>{gameMsg.map((item, i) => <div className="fs-5 mb-3" key={i}>{item}</div>)}</div>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="primary"
							id="next_game"
							onClick={this.handleClose}
						>
							{buttonMsg}
						</Button>
					</Modal.Footer>
				</Modal>
			</>
		);
	};

	render() {
		return this.formatModal();
	}
}

export { TutorialResult };
