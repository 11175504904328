import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuthDispatch } from '../../service/store/AuthContext';
import signIn from '../../service/auth/api/signIn';
import { useValidation } from '../../hooks';

export const SignInForm = ({ email, setIsForgotPassword, handleToggleForm }) => {
  const authDispatch = useAuthDispatch();
  const { mutateAsync, isLoading } = useMutation(signIn);
  const [errorMessage, setErrorMessage] = useState('');

  const { emailValidation, passwordValidation } = useValidation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isResetConfirm = queryParams.get('reset');

  const AuthSchema = Yup.object().shape({
    email: emailValidation,
    password: passwordValidation,
  });

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const clearParams = () => {
    navigate(location.pathname, { replace: true });
  };

  const handleSignIn = async values => {
    setErrorMessage('');

    try {
      const data = await mutateAsync({
        email: values.email,
        password: values.password,
      });
      localStorage.setItem('authToken', data.auth_token);
      authDispatch({ type: 'SET_TOKEN', token: data.auth_token });
      if (isResetConfirm) {
        clearParams();
      }
    } catch (error) {
      setErrorMessage('Invalid email or password');
    }
  };

  const goHome = () => navigate('/');

  const handleForgotPassword = () => {
    setIsForgotPassword(true);
  };

  return (
    <>
      <div className="fs-4 text-center fw-bolder">Sign In</div>
      <hr />
      {isResetConfirm ? (
        <p className="text-center">
          Your password has been successfully reset. <br /> Please sign in using your new password.
        </p>
      ) : null}
      <Formik
        initialValues={{ email: email || '', password: '' }}
        validationSchema={AuthSchema}
        onSubmit={handleSignIn}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email</Form.Label>
              <Field type="email" name="email" placeholder="Enter email" className="form-control" />
              <div className="position-absolute start-16">
                <ErrorMessage name="email" component="div" className="text-danger" />
              </div>
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <div className="input-group">
                <Field
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  placeholder="Password"
                  className="form-control"
                />
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <i className="bi bi-eye-slash"></i>
                  ) : (
                    <i className="bi bi-eye"></i>
                  )}
                </button>
              </div>
              <div className="position-absolute start-16">
                <ErrorMessage name="password" component="div" className="text-danger" />
              </div>
            </Form.Group>
            <div className="container col-12 justify-content-center align-items-center mt-1">
              <div className="row ">
                <p className="text-danger col-8 m-0 p-0">{errorMessage ? errorMessage : ''}</p>
                <div className="col-4 p-0 text-end">
                  <Button
                    variant="link"
                    type="button"
                    onClick={handleForgotPassword}
                    className="p-0"
                    style={{
                      textDecoration: 'none',
                      fontSize: '16px',
                    }}
                  >
                    Forgot Password?
                  </Button>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center gap-3 mt-5">
              <Button
                variant="primary"
                type="submit"
                className=" position-relative px-5 d-flex justify-content-center align-items-center"
              >
                Sign In
                {isLoading && (
                  <span
                    className="spinner-border spinner-border-sm  position-absolute mx-3 end-0 m-6 top-10"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </Button>
              <Button
                variant="danger"
                type="button"
                onClick={goHome}
                className=" position-relative px-5 d-flex justify-content-center align-items-center"
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="text-center mt-3">
        <div className="d-flex justify-content-center align-items-center">
          <p className="mb-0 me-2">Don't have an account?</p>
          <button
            className="btn btn-link p-0"
            onClick={() => {
              handleToggleForm();
              clearParams();
            }}
          >
            Sign Up
          </button>
        </div>
      </div>
    </>
  );
};
