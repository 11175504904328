import { useQuery } from '@tanstack/react-query';
import { getMyProfile } from './getMyProfile';

export function useMyProfile(authToken) {
  return useQuery(['GetMyProfile'], () => getMyProfile(authToken), {
    staleTime: 600000,
  });
}

export function useMyProfileLazy(authToken) {
  return useQuery(['GetMyProfile'], () => getMyProfile(authToken), {
    enabled: false,
  });
}
