import axios from 'axios';

const resetPassword = async formData => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}api/v1/auth/users/reset_password_confirm/`,
      formData,
    );
    return response;
  } catch (error) {
    if (error.response.data || error.response.data) {
      return { error: 'Link has expired' };
    }
    throw new Error(error.response.data.message);
  }
};

export default resetPassword;
