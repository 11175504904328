import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { HistoryTab, ProfileTab, Tabs } from '../components';
import TopNavbar from '../components/navbar';
import { useAuthDispatch, useAuthState } from '../service/store/AuthContext';
import { useMyProfile } from '../components/ProfileTabs/api/useMyProfile';

const getTabs = ({ profileData, isProfileLoading, handleLogout, token }) => [
  {
    id: 'profile',
    label: 'Profile',
    content: (
      <ProfileTab
        profileData={profileData}
        isProfileLoading={isProfileLoading}
        handleLogout={handleLogout}
        token={token}
      />
    ),
  },
  {
    id: 'history',
    label: 'Game History',
    content: (
      <HistoryTab profileData={profileData} isProfileLoading={isProfileLoading} token={token} />
    ),
  },
];

export const ProfilePage = () => {
  const { token } = useAuthState();
  const navigate = useNavigate();
  const authDispatch = useAuthDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedTab = +queryParams.get('selectedTab');

  const { data: profileData, isLoading: isProfileLoading } = useMyProfile(token);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('authToken');
    authDispatch({ type: 'LOG_OUT' });
    navigate('/');
  }, [authDispatch, navigate]);

  useEffect(() => {
    if (!token) {
      navigate('/play');
      handleLogout();
    }
  }, [handleLogout, navigate, token]);

  if (profileData?.status) {
    navigate('/play');
    handleLogout();
    return;
  }
  return (
    <>
      <TopNavbar />
      <div className="container mx-auto">
        <Tabs
          tabs={getTabs({ profileData, isProfileLoading, handleLogout, token })}
          selectedTab={selectedTab}
        />
      </div>
    </>
  );
};
