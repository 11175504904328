import React from 'react';
import TopNavbar from '../navbar';

const Mainpage = () => {
	return (
		<>
			<div>
				<TopNavbar />
			</div>
			<div>
				<div
					className="container text-center"
					style={{ marginTop: '5vw' }}
				>
					<div className="display-4 fw-bold">
						Playing chess with the aid of AI generated tips
					</div>
					<div className="fw-bold fs-2">
						We study how AI tips help chess players
					</div>
					<div className="fw-light fs-3 text-info">
						Test our engine and see if you can find all the optimal
						moves during your games!
					</div>
				</div>
				<div
					className="container p-4"
					style={{
						backgroundColor: 'rgb(0,0,0,0)',
						marginTop: '8vw',
					}}
				>
					<div className="row">
						<div className="col-6">
							<img
								src="./img/chess_1.jpg"
								alt="chess1"
								style={{ width: '100%' }}
							/>
						</div>
						<div className="col">
							<div className="fw-bold fs-3 p-4">
								Can AI help expert chess players find the
								optimal moves?
							</div>
							<div className="fw-light fs-5 p-4">
								Given enough time and peace of mind, expert
								chess players can find the optimal move in any
								position. But many times, during real games,
								they have neither. Can AI help them find the
								optimal moves during real games without
								revealing them? We explore when and how AI tips
								can help experts over-perform.
							</div>
						</div>
					</div>
				</div>
				<div
					className="container p-4"
					style={{
						backgroundColor: 'rgb(0,0,0,0)',
						marginTop: '8vw',
					}}
				>
					<div className="row">
						<div className="col">
							<div className="fs-3 fw-bold">
								In our <a href="/play">"Play"</a> page you can
								play games with:
							</div>
							<div
								className="fs-5 fw-lighter"
								style={{
									marginLeft: '0.5vw',
								}}
							>
								<ul>
									<li>No help (regular chess)</li>
									<li>
										Minimal tips (playing with additional
										help)
									</li>
									<li>
										Full tip (playing with additional help)
									</li>
								</ul>
							</div>
							<div>
								<img
									src="./img/minimal_tips.png"
									alt="chess1"
									style={{ width: '80%' }}
									className='mt-5'
								/>
							</div>
							<div>
								<img
									src="./img/full_tips.png"
									alt="chess1"
									style={{ width: '80%' }}
									className='mt-5'
								/>
							</div>
						</div>
						<div className="col-6">
							<img
								src="./img/chess_2.jpg"
								alt="chess1"
								style={{ width: '100%' }}
							/>
						</div>
					</div>
				</div>
				<div
					className="container p-4"
					style={{
						backgroundColor: 'rgb(0,0,0,0)',
						marginTop: '8vw',
					}}
				>
					<div className="row align-items-center">
						<div className="col-6">
							<img
								src="./img/chess_3.jpg"
								alt="chess1"
								style={{ width: '100%' }}
							/>
						</div>
						<div className="col">
							<div className="fw-bold fs-3 p-4">
								Go to <a href="/tutorial">Tutorial</a> to
								experience AI tips, and then{' '}
								<a href="/play">Play</a> against our
								Stockfish-based AI chess engine
							</div>
						</div>
					</div>
				</div>
				<div
					className="container p-4"
					style={{
						backgroundColor: 'rgb(0,0,0,0)',
						marginTop: '8vw',
					}}
				>
					<div className="row align-items-center">
						<div className="col">
							<div className="fw-bold fs-3 p-4">
								Participate in the official experiment
								and earn money for playing against our
								AI chess engine
							</div>
							<div className="fw-light fs-4 p-4">
							To show your interest in participating, complete the following <a href="https://wharton.qualtrics.com/jfe/form/SV_e5NF3rIwHqX5kF0">
							form
							</a>.
							</div>

						</div>
						<div className="col-6">
							<img
								src="./img/chess_4.jpg"
								alt="chess1"
								style={{ width: '100%' }}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Mainpage;
