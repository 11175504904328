import axios from 'axios';

export const updateProfile = async (formData, authToken) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_API}api/v1/auth/users/update/`,
      formData,
      {
        headers: {
          Authorization: `Token ${authToken}`,
        },
      },
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};
